@font-face {
font-family: '__recklessNeueFont_17379f';
src: url(/_next/static/media/9647e7949d6a9693-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__recklessNeueFont_Fallback_17379f';src: local("Arial");ascent-override: 96.48%;descent-override: 20.70%;line-gap-override: 0.00%;size-adjust: 102.41%
}.__className_17379f {font-family: '__recklessNeueFont_17379f', '__recklessNeueFont_Fallback_17379f'
}.__variable_17379f {--f-reckless-neue: '__recklessNeueFont_17379f', '__recklessNeueFont_Fallback_17379f'
}

@font-face {
font-family: '__bigShouldersDisplayFont_c407a2';
src: url(/_next/static/media/3ab483d35e87d382-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__bigShouldersDisplayFont_Fallback_c407a2';src: local("Arial");ascent-override: 116.36%;descent-override: 25.19%;line-gap-override: 0.00%;size-adjust: 84.57%
}.__className_c407a2 {font-family: '__bigShouldersDisplayFont_c407a2', '__bigShouldersDisplayFont_Fallback_c407a2'
}.__variable_c407a2 {--f-big-shoulders-display: '__bigShouldersDisplayFont_c407a2', '__bigShouldersDisplayFont_Fallback_c407a2'
}

@font-face {
font-family: '__abcFavoritProFont_696be2';
src: url(/_next/static/media/88335a0211d83f7b-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__abcFavoritProFont_Fallback_696be2';src: local("Arial");ascent-override: 98.62%;descent-override: 30.94%;line-gap-override: 0.00%;size-adjust: 103.42%
}.__className_696be2 {font-family: '__abcFavoritProFont_696be2', '__abcFavoritProFont_Fallback_696be2'
}.__variable_696be2 {--f-abc-favorit-pro: '__abcFavoritProFont_696be2', '__abcFavoritProFont_Fallback_696be2'
}

@font-face {
font-family: '__jvSignatureFont_3e2d5c';
src: url(/_next/static/media/2175f93b678903e1-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__jvSignatureFont_Fallback_3e2d5c';src: local("Arial");ascent-override: 102.42%;descent-override: 13.37%;line-gap-override: 0.00%;size-adjust: 80.75%
}.__className_3e2d5c {font-family: '__jvSignatureFont_3e2d5c', '__jvSignatureFont_Fallback_3e2d5c'
}.__variable_3e2d5c {--f-jv-signature: '__jvSignatureFont_3e2d5c', '__jvSignatureFont_Fallback_3e2d5c'
}

